import React from "react";
import { graphql, Link } from "gatsby";
import Seo from "../components/shared/Seo";
import Hero from "../components/shared/Hero";
import Quote from "../components/shared/Quote";
import * as styles from "./styles/IndexPage.module.scss";
import { animatedWave } from "../utils/shapes";
import { getRandomNumber } from "../utils/helpers";

const IndexPage = ({ data }) =>
{
	const { data: pageData } = data.allPrismicHome.edges[0].node;

	return (
		<>
			<Seo
				title={pageData.title.text}
				description={pageData.description.text}
			/>

			<div className={styles.homePage}>
				<Hero
					picture={pageData.hero_picture}
					title={pageData.hero_title.text}
					description={pageData.hero_description.text}
					cta={{
						text: pageData.hero_cta_text.text,
						url: pageData.hero_cta_link.text,
					}}
					fullHeight={true}
				/>

				<Quote
					text={pageData.quote_text.text}
					author={pageData.quote_author.text}
				/>

				<div className={styles.internalLinksWrapper}>
					{pageData.internal_links.length &&
						pageData.internal_links.map((link, i) => (
							<Link key={i} to={link.link_url.text}>
								<span>{link.link_label.text}</span>

								{animatedWave(undefined, `${getRandomNumber(150, 250)}px`, "#2e8f9a", .85, getRandomNumber(25, 35))}
								{animatedWave(undefined, `${getRandomNumber(150, 250)}px`, "#9fbbbe", .25, getRandomNumber(25, 35))}
								{animatedWave(undefined, `${getRandomNumber(150, 250)}px`, "#2c6268", 1, getRandomNumber(25, 35))}
								{animatedWave(undefined, `${getRandomNumber(150, 250)}px`, "#2e8f9a", .75, getRandomNumber(25, 35))}
								{animatedWave(undefined, `${getRandomNumber(150, 250)}px`, "#2c6268", .7, getRandomNumber(25, 35))}
							</Link>
						))}
				</div>

				<div className={styles.externalLinksWrapper}>
					<div>
						{pageData.external_links.length &&
							pageData.external_links.map((link, i) => (
								<a
									key={i}
									target="_blank"
									rel="noreferrer noopener"
									href={link.link_url.text}
								>
									<img src={link.link_logo.fluid.src} alt={link.link_logo.alt} />
									<span>{link.link_label.text}</span>
								</a>
							)
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export const query = graphql`
	{
		allPrismicHome {
			edges {
				node {
					data {
						title {
							text
						}
						description {
							raw
						}
						hero_picture {
							fluid(maxWidth: 800) {
								src
							}
							alt
						}
						hero_title {
							text
						}
						hero_description {
							text
						}
						hero_cta_text {
							text
						}
						hero_cta_link {
							text
						}
						quote_text {
							text
						}
						quote_author {
							text
						}
						internal_links {
							link_label {
								text
							}
							link_url {
								text
							}
						}
						external_links {
							link_label {
								text
							}
							link_url {
								text
							}
							link_logo {
								fluid(maxWidth: 800) {
									src
								}
								alt
							}
						}
					}
				}
			}
		}
	}
`;

export default IndexPage;
