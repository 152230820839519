import React from "react";
import { Link } from "gatsby";
import { className } from "../../../utils/helpers";
import { pictureShape, pictureShapeClip, pictureShapeAlt, pictureShapeAltClip, circleShape, wavesShape } from "../../../utils/shapes";
import * as styles from "./Hero.module.scss";

const Hero = ({
	picture,
	title,
	description,
	cta,
	fullHeight = false,
	altShape = false,
}) => (
	<div
		{...className(styles.hero, {
			[styles.fullHeight]: fullHeight,
		})}
	>
		{circleShape}
		{wavesShape}

		<div
			{...className(styles.heroPicture, {
				[styles.hasAltShape]: altShape,
			})}
		>
			{altShape ? pictureShapeAlt : pictureShape}
			{altShape ? pictureShapeAltClip : pictureShapeClip}
			{picture && <img src={picture.fluid.src} alt={picture.alt} />}
		</div>

		<div className={styles.heroText}>
			{title && <h3>{title}</h3>}
			{description && <p>{description}</p>}

			{cta && (
				<Link to={cta.url} className="base-cta">
					{cta.text}
				</Link>
			)}
		</div>
	</div>
);

export default Hero;
